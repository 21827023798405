import { OrderResponse } from "models/Order";
import api from "services/api";
import { filtersToUrl } from 'utils/filters';
import { notifyAlert, notifyError } from "utils/notification";

export async function getOrders(page: number, token: string, filters = {}) {
  try {
    const filtersUrl = filtersToUrl(filters);
    const response = await api.get<OrderResponse>(
      `${process.env.REACT_APP_API_URL}/orders?page=${page}${filtersUrl}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os pedidos.");
  }
}

export async function getAbandonedCart(page: number, token: string, filters = {}) {
  try {
    const filtersUrl = filtersToUrl(filters);
    const response = await api.get<OrderResponse>(
      `${process.env.REACT_APP_API_URL}/abandoned-carts?page=${page}${filtersUrl}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os carrinhos abandonados.");
  }
}

export async function cancelOrder(orderId: string, paymentMethod: string, token: string) {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_API_URL}/cancel-order/${orderId}?payment_type=${paymentMethod}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      notifyError(error.response.data.message);
    } else {
      notifyError("Ops! Tivemos um problema ao cancelar o pedido.");
    }
  }
}

export async function updateTrackingCode(orderId: number, trackingCode: string, trackingUrl: string, token: string) {
  const response = await api.post(
    `${process.env.REACT_APP_API_URL}/update-tracking-code/${orderId}`,
    {
      tracking_code: trackingCode,
      tracking_url: trackingUrl,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function updateTrackingExcel(ordersData: any[], token: string, userId: number) {
  const response = await api.post(
    `${process.env.REACT_APP_API_URL}/update-tracking-excel`,
    { orders: ordersData, userId: userId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

