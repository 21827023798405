import React from 'react';
import { Image, Variation, Product } from 'models/Product';
import { Box, Flex, Text } from '@chakra-ui/react';
import ImageList from 'components/images/ImageList';
import DetailsText from 'components/details/DetailsText';
import CopyField from 'components/fields/CopyField';

// Função para obter o símbolo da moeda
const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'BRL':
      return 'R$';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'JPY':
      return '¥';
    default:
      return ''; // Caso a moeda não seja encontrada, retorna uma string vazia.
  }
};

type ProductDetailsVariationsProps = {
  variations: Variation[],
  images: Image[],
  product: Product
}

export default function ProductDetailsVariations({ variations, images, product }: ProductDetailsVariationsProps) {
  const padding = "10px 20px";

  return (
    <Box padding={padding}>
      <Text fontSize={{base: "22px", md: "28px"}} fontWeight="bold" mb="10px">
        Variações
      </Text>
      <Flex py={padding} flexWrap="wrap" my="10px" gap={4}>
        {variations.map((variation: Variation, index: number) => (
          <Flex
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            justifyContent="space-between"
            flexWrap="wrap"
            w="400px"
            p="14px"
          >
            <Box w={{base: "100%", md: "49%"}}>
              <Text as="p" fontSize={{base: "20px", md: "22px"}} fontWeight="bold" mb={2}>
                {variation.name}
              </Text>
              <DetailsText 
                title={"Preço"} 
                value={`${getCurrencySymbol(product.currency)} ${variation.price}`} // Usando a função para exibir o símbolo correto da moeda
              />
              {variation.checkout_url && (
                <DetailsText title={"Url do Checkout"} value={
                  <CopyField text={variation.checkout_url} onlyIcon />
                } />
              )}
            </Box>
            <Flex w={{base: "100%", md: "39%"}}>
              <Box width={150}>
                <ImageList
                  images={
                    images.filter(image => image.id === variation.image_id).map(image => ({
                      url: image.src,
                      alt: `Imagem da variação ${variation.name}`
                    }))
                  }
                  width={150}
                  height={150}
                />
              </Box>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
