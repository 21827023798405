import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Collapse,
  Switch,
  Fade,
  SlideFade
} from "@chakra-ui/react";
import { Container, Body, Row, RowFooterButton, SecondPageBody } from "../styles"
import PersonalDataStep from "../components/PersonalDataStep"
import LegalEntityDataStep from "../components/LegalEntityData/LegalEntityDataStep"
import { usePerfilPage } from "../store/index";
import BankingAccountForm from "../components/Banking/BankingAccountForm";
import { updateBankingAccount, updateLegalEntity } from "services/userRequests"
import { UpdateBankingAccount, UpdateLegaEntity } from "models/User"
import { useUserStore } from "store/reducers/user";
import { updateOnboardingStep } from "services/userRequests";
import { CustomButton } from "styleguide/Button"
import { toastSuccess, toastError } from "styleguide/Toastify"

const SecondPage = () => {
  const [formData, setFormData] = useState({});
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true)
  }, [])
  const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } = usePerfilPage();
  const [updateEntityData, user, updateUserOnboardingStep, updateBankingAccountData] = useUserStore(state => [state.updateEntityData, state.user, state.updateUserOnboardingStep, state.updateBankingAccount]);
  const [isValidForm, setIsValidForm] = useState(false);
  type FormInfoType = UpdateBankingAccount.BankingAccount | UpdateLegaEntity.LegalEntity | null;
  const [formInfo, setFormInfo] = useState<FormInfoType>(null);

  const handlePreviousStep = () => {
    setFade(false)
    setTimeout(() => {
      setOnBoardingPage(0 + (hasCnpj ? 0 : 0.5))
    }, 350)
  }

  useEffect(() => {
    onboardingPage > 1 && setIsValidForm(false);
  }, [])


  const handleNextStep = async () => {

    try {

      if (!hasCnpj && 'agencia' in formInfo) {
        const data = await updateBankingAccount({
          holder_name: user.name,
          agencia: formInfo.agencia,
          banco_id: formInfo.banco_id,
          conta: formInfo.conta,
          n_pix: formInfo.n_pix
        })
        if (!data.success) {
          toastError("Erro ao salvar documento")
          return
        }
        updateBankingAccountData(data.data);
      } else {
        if ('cnpj' in formInfo) {
         
          const response = await updateLegalEntity({
             cnpj_razao: formInfo.cnpj_razao,
            cnpj_social: formInfo.cnpj_social,
            cnpj_zipcode: formInfo.cnpj_zipcode,
            cnpj_address: formInfo.cnpj_address,
            cnpj_city: formInfo.cnpj_city,
            cnpj_state: formInfo.cnpj_state,
            cnpj_complement: formInfo.cnpj_complement,
            cnpj_number: formInfo.cnpj_number,
            cellphone: user.cellphone,
            cnpj: formInfo.cnpj,
            email: user.email,
            neighborhood: formInfo.neighborhood

            });
            if (response.success){

              updateEntityData(formInfo);
            } else {
              toastError("Erro ao criar pessoa juridica")
              return
            }
        }
      }

      if (onboardingPage == 1) {

        const response = await updateOnboardingStep({
          onboarding_step: 2
        });

        if (response.success) {
          setFade(false)
          toastSuccess("Operação realizada com sucesso")
          setTimeout(() => {
            setOnBoardingPage(2);
          }, 350)
        }

      } else {
        setFade(false)
        toastSuccess("Operação atualizada com sucesso")
        setTimeout(() => {
          setOnBoardingPage(2)
        }, 350)

      }
    } catch (err) {
      toastError("Houve uma falha na operação. Tente novamente ou entre em contato com o suporte")
    }
  }

  const handleSkipStep = () => {
    setFade(false)
    setTimeout(() => {
      setOnBoardingPage(2)
    }, 350)

  }


  return (
    <React.Fragment>
      <SlideFade unmountOnExit={true} in={fade} offsetY='50px' reverse={true}>
        <Container>

          <SecondPageBody>

            {!hasCnpj ?
              <Row>
                <BankingAccountForm setFormIsValid={setIsValidForm} setFormInfo={setFormInfo} />
              </Row>
              :
              <React.Fragment>
                <Row>
                  <LegalEntityDataStep setFormIsValid={setIsValidForm} setFormInfo={setFormInfo} />
                </Row>
              </React.Fragment>
            }
            <RowFooterButton >
              <CustomButton
                isFullWidth={false}
                variant={"tertiary"}
                type="button"
                onClick={handlePreviousStep}
              >
                Voltar
              </CustomButton>
              <CustomButton
                isFullWidth={false}
                disabled={!isValidForm}
                variant={"primary"}
                onClick={handleNextStep}
              >
                Salvar e Avançar
              </CustomButton>
              {
                user.onboarding_step > 1 &&
                <CustomButton
                  isFullWidth={false}
                  variant={"secondary"}
                  onClick={handleSkipStep}
                >
                  Próximo
                </CustomButton>
              }
            </RowFooterButton>
          </SecondPageBody>
        </Container>
      </SlideFade>
    </React.Fragment>
  )
}


export default SecondPage;