import * as React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Checkbox,
  Flex,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import DubpayLogo from './dubpay_logo.png';
import PersonalDataStep from "./components/PersonalDataStep"
import axios from "axios";
import { parse } from "papaparse";
import $ from "jquery";
import "jquery-mask-plugin";
import { useUserStore } from 'store/reducers/user';
import { User } from "@supabase/gotrue-js";
import "./UserReports.css";
import "bootstrap";
import { reverse } from "dns";
import { FaFilePdf, FaArrowLeft, FaArrowRight, FaPaperclip } from 'react-icons/fa';
import { MdHeight } from "react-icons/md";
import { Container, Body, Row, RowFooter } from "./styles"
import Stepper from "react-stepper-horizontal";
import PersonalBankingStep from "./components/Banking/BankingAccountForm";
import PersonalDocumentsStep from "./components/PersonalDocumentsStep";
import LegalEntityDataStep from "./components/LegalEntityData/LegalEntityDataStep";
import SecondStep from './pages/SecondStep'
import LegalEntityBankingStep from "./components/LegalEntityBankingStep";
import LegalEntityDocumentsStep from "./components/LegalEntityDocumentsStep";
import FinishStep from "./components/FinishStep/FinishStep";
import {getUserRequest} from "services/userRequests"
import ThirdStep from "./pages/ThirdStep";
import FourthStep from "./pages/FourthStep";
import FifthStep from "./pages/FifthStep";
import FirstStep from "./pages/FirstStep";
import {usePerfilPage} from "./store/index"
import Approved from "./components/FinishStep/Approved";
import EditingStep from "./components/FinishStep/EditingStep";

export default function UserReports() {

  const [auth, updateUser, user] = useUserStore((state) => [state.auth, state.updateUser, state.user]);
  const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } = usePerfilPage();
  const MAX_CHUNK_SIZE = 512 * 1024;
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const MAX_IMAGE_SIZE = 10 * 1024 * 1024;

  const [activeTab, setActiveTab] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalshowErro, setModalErro] = React.useState(false);


  const [erro, setErro] = React.useState("");
  
  const [status, setStatus] = React.useState("");
  const [banco_id, setBanco_id] = React.useState("");
  const [agencia, setAgencia] = React.useState("");
  
  
  const [cnpj_agencia, setCnpj_agencia] = React.useState("");
  const [cnpj_conta, setCnpj_conta] = React.useState("");
  const [comprovante_endereco, setComprovanteEndereco] = React.useState(null);
  const [dataselect, setDataselect] = React.useState([]);
  const [isLoadingStatus, setIsLoadingStatus] = React.useState(false);
  const [modalshowpedido, setModalpedido] = React.useState(false);
  const [dataselectb, setDataselectb] = React.useState([]);
  const [modalshowsucesso, setModalsucesso] = React.useState(false);
  const [modalshowcancel, setModalcancel] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  
  //tutorial
  const { isOpen, onOpen, onClose } = useDisclosure();
 
  React.useEffect(() => {
    (
      async () => { 
        const response = await getUserRequest()
        if (response.success) {
          const {data} = response;
          setOnBoardingPage(data.onboarding_step);
          setHasCnpj(data.prefers_cnpj)
          updateUser(data);
        } 
      })()
  }, []);

 
  const handleCloseErro = () => {
    setModalErro(false);
  };

  const handleClosesucesso = () => {
    setModalsucesso(false);
  };


  const handleClosepedido = () => {
    setModalpedido(false);
  };

  const handleShowpedido = () => setModalpedido(true);

  const handleClosecancel = () => {
    setModalcancel(false);
  };
  const handleShowcancel = () => setModalcancel(true);

  //auth.user.complete_register

  React.useEffect(() => {
    if (status != 'Aprovado') {
      auth.user.complete_register = false;
    }
  }, [status]);

  
  return (

    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Modal isOpen={modalshowpedido} onClose={handleClosepedido}>
        <ModalContent>
          <ModalHeader>Pedido de analise!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Seu pedido foi enviado a nossa equipe, assim que tivermos uma resposta, você será notificado via email, aguarde!
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClosepedido}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalshowErro} onClose={handleCloseErro}>
        <ModalContent>
          <ModalHeader>Ops!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {erro}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleCloseErro}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalshowsucesso} onClose={handleClosesucesso}>
        <ModalContent>
          <ModalHeader>Arquivo!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Arquivo carregado com sucesso!<br></br>
            Carregue todos os aquivos!<br></br>
            Depois vá em salvar para continuar!
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClosesucesso}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalshowcancel} onClose={handleClosecancel} >
        <ModalContent>
          <ModalHeader>Agora você pode editar!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Seus dados agora podem ser editados, mas após a edição você deve solicitar a analise novamente, entrando na fila de analises, aguarde!
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClosecancel}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Container>

        <Body>

         

          <Box style={{
            flex: "1",
            borderRadius: "4px",
            padding: "10px"
          }}
          >
             {
            onboardingPage === 0 && <FirstStep/>
            }

          {
            onboardingPage === 0.5 && (
              <EditingStep user={user}/>
            )
          }

            {onboardingPage === 1 && (
              <SecondStep/>
            )
            }

            {onboardingPage === 2 &&
              <ThirdStep />
            }

            {onboardingPage === 3 &&
              <FourthStep />
            }


            {onboardingPage === 4 &&
              <FifthStep/>
            }
            
          </Box>
        </Body>

        
      </Container>
    </Box>
  );
}


