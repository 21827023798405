import { Store } from './Store';
import { Response } from './Request';
import { ImageFile } from './File';

export interface ProductResponse extends Response<Product[]> {}

export enum ProductType {
  PHYSICAL = 'physical',
  DIGITAL = 'digital',
}

export interface Image {
  id: number;
  src: string;
}

export interface ProductList {
  id: number;
  name: string;
}

export interface Product {
  id: number;
  body_html: string;
  imported_code: string;
  name: string;
  type: ProductType;
  status: string;
  description: string;
  with_variation: boolean;
  store: Store;
  variations: Variation[];
  images: Image[];
  source: string;
  currency: 'BRL' | 'USD' | 'EUR' | 'GBP' | 'JPY'; // Adicionado o campo 'currency'
}

export interface Variation {
  id: number;
  name: string;
  compare_at_price: string;
  cost_per_item: string;
  grams: string;
  imported_code: string;
  price: string;
  status: string;
  product_id: number;
  image_id: number;
  checkout_url: string;
}

export interface CreateProductRequest {
  name: string;
  type: ProductType;
  store_id: number;
  price: string;
  description: string;
  variations: CreateVariationRequest[];
  images?: ImageFile[];
  currency: 'BRL' | 'USD' | 'EUR' | 'GBP' | 'JPY'; // Adicionado o campo 'currency'
}

export interface CreateVariationRequest {
  name: string;
  price: string;
  compare_at_price?: string;
  cost_per_item?: string;
  grams?: string;
  image?: ImageFile[];
  currentImage?: Image;
  id?: number;
  key: string;
}

export namespace SyncProducts {
  export type Result = {
    success: boolean
  }
}
