import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
    Spinner,
    Text,
    useColorModeValue,
    Select,
    useDisclosure,
} from '@chakra-ui/react';
import CheckTable from './CheckTableDomain';
import Card from 'components/card/Card';
import axios from 'axios';
import { useUserStore } from 'store/reducers/user';
import InsertSubdomainModal from './InsertSubdomainModal';

export default function Domains() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const [show, setShow] = useState(false);
    const [domainsData, setDomainsData] = useState([]);
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        domain: '',
        store: '',
    });
    const [isDomainValid, setIsDomainValid] = useState(false);
    const [domainError, setDomainError] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    // const [isLoadingInsert, setIsLoadingInsert] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedDomain, setSelectedDomain] = useState(null);
    const token = useUserStore.getState().auth.token;

    const fetchDomains = async () => {
        try {
            setLoading(true);  
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-stores-domains`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response && response.data) {
                setDomainsData(response.data);
            } else {
                console.error('Error fetching domains: Invalid response', response);
            }
        } catch (error) {
            console.error('Error fetching domains:', error);
        } finally {
            setLoading(false);  
        }
    };

    const fetchStores = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-stores`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response && response.data) {
                console.log('Stores response:', response.data);
                setStores(response.data);
            } else {
                console.error('Error fetching stores: Invalid response', response);
            }
        } catch (error) {
            console.error('Error fetching stores:', error);
        }
    };

    useEffect(() => {
        fetchDomains();
        fetchStores();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'domain') {
            setDomainError('');
            setIsDomainValid(false);
        }
    };

    const validateSubdomain = (subdomain) => {
        const subdomainPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        return subdomainPattern.test(subdomain);
    };

    const checkDomainAvailability = async (domain) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/check-domain`, {
                params: { domain },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.exists;
        } catch (error) {
            console.error('Erro ao verificar o domínio:', error);
            setDomainError('Erro ao verificar o domínio.');
            return false;
        }
    };

    const handleSaveForm = async () => {
        if (!validateSubdomain(formData.domain)) {
            setDomainError('Subdomínio inválido. Use o formato subdominio.dominio.com');
            return;
        }

        setIsSaving(true);
        const isDomainInUse = await checkDomainAvailability(formData.domain);
        if (isDomainInUse) {
            setDomainError('Domínio em uso, tente outro.');
            setIsSaving(false);
            return;
        }

        try {
            const storeDomains = domainsData.filter(domain => domain.store_id === formData.store);
            if (storeDomains.length === 0) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/domains`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response && response.data) {
                    fetchDomains();  
                    setFormData({ domain: '', store: '' });  
                    setShow(false);
                } else {
                    console.error('Error saving domain: Invalid response', response);
                }
            } else {
                alert('A loja já possui um domínio registrado.');
            }
        } catch (error) {
            console.error('Error saving domain:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDeleteDomain = async (domain) => {
        setLoading(true); 
        setDomainsData([]); 

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/domains/${domain}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                await fetchDomains(); 
                await fetchStores(); 
            } else {
                console.error('Error deleting domain: Invalid response', response);
            }
        } catch (error) {
            console.error('Error deleting domain:', error);
        } finally {
            setLoading(false); 
        }
    };

    const handleInsertSubdomain = (domain) => {
        setSelectedDomain(domain);
        onOpen();
    };

    const handleNewSubdomain = async () => {
        await fetchStores();  
        setFormData({ domain: '', store: '' });  
        setShow(!show);
    };

    // const handleProceedInsert = async () => {
    //     setIsLoadingInsert(true);
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/insert-subdomain`, {
    //             domain: selectedDomain.name,
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         if (response.status === 200) {
    //             alert('Subdomínio inserido com sucesso!');
    //             onClose();
    //             fetchDomains(); 
    //         } else {
    //             console.error('Error inserting subdomain: Invalid response', response);
    //         }
    //     } catch (error) {
    //         console.error('Error inserting subdomain:', error);
    //     } finally {
    //         setIsLoadingInsert(false); 
    //     }
    // };

    const isFormValid = formData.domain !== '' && formData.store !== '';

    return (
        <>
            <Box mb="20px">
                <Text fontSize="lg" fontWeight="bold">Passo a Passo para Cadastrar Subdomínio no Checkout</Text>
                <Text>1. Certifique-se de que você tem um domínio próprio sendo usado na sua loja. Por exemplo: sualoja.com</Text>
                <Text>2. Acesse o site onde comprou o seu domínio e crie um subdomínio. Por exemplo: checkout.sualoja.com</Text>
                <Text>3. Após criar o subdomínio, registre-o na nossa plataforma clicando em "Novo Subdomínio".</Text>
                <Text>4. Depois de registrar o subdomínio na plataforma da DubPay, clique em "Configurar".</Text>
                <Text>5. Copie as configurações de DNS que serão disponibilizadas em tela e configure na plataforma de hospedagem.</Text>
                <Text>Atenção: A mudança do status de  "PROCESSANDO" para "ATIVO" ocorre automaticamente após configuração. Caso não ocorra a configuração ou o preenchimento errado de algum campo, após 48 horas, o status mudará para "INATIVO".</Text>
            </Box>

            <Button onClick={handleNewSubdomain}>{show ? 'Voltar' : 'Novo Subdomínio'}</Button>

            {show ? (
                <Card flexDirection="column" marginTop={'10px'} w="100%" px="20px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                    <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
                        {stores.length > 0 ? (
                            <FormControl rowGap="20px">
                                <FormLabel htmlFor="domain">Subdomínio</FormLabel>
                                <Input id="domain" type="text" name="domain" value={formData.domain} onChange={handleInputChange} />
                                {domainError && <Text color="red.500">{domainError}</Text>}

                                <FormLabel htmlFor="store">Loja</FormLabel>
                                <Select id="store" name="store" value={formData.store} onChange={handleInputChange}>
                                    <option value="">Selecione uma loja</option>
                                    {stores.map((store) => (
                                        <option key={store.id} value={store.id}>
                                            {store.name}
                                        </option>
                                    ))}
                                </Select>

                                <Button onClick={handleSaveForm} isDisabled={!isFormValid} isLoading={isSaving}>
                                    Salvar
                                </Button>
                            </FormControl>
                        ) : (
                            <Text>Você não tem loja para cadastrar um subdomínio. Cadastre uma loja primeiro.</Text>
                        )}
                    </Box>
                </Card>
            ) : (
                <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
                    {loading ? (
                        <Center>
                            <Spinner size="xl" />
                        </Center>
                    ) : (
                        <>
                            {domainsData.length > 0 ? (
                                <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
                                    <CheckTable tableData={domainsData} onDelete={handleDeleteDomain} onInsertSubdomain={handleInsertSubdomain} />
                                </SimpleGrid>
                            ) : (
                                <Text>Você ainda não possui subdomínios cadastrados.</Text>
                            )}
                        </>
                    )}
                </Box>
            )}
            {/* <InsertSubdomainModal isOpen={isOpen} onClose={onClose} onProceed={handleProceedInsert} domain={selectedDomain} isLoading={isLoadingInsert} /> */}
        </>
    );
}