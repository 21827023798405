import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle
} from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import { useUserStore } from 'store/reducers/user';
import { updateTrackingExcel } from 'controllers/Orders';

interface ImportExcelModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImportExcelModal: React.FC<ImportExcelModalProps> = ({ isOpen, onClose }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [errors, setErrors] = useState<{ orderNumber: string, error: string }[]>([]);
  const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
  const token = useUserStore((state) => state.auth.token);
  const userId = useUserStore((state) => state.auth.user.id);
  console.log(userId);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };  

  const handleImportClick = async () => {
    if (!selectedFile) return;

    setIsLoading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        if (!data) {
          console.error('No data found in file');
          return;
        }

        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = jsonData[0];
        const dataRows = jsonData.slice(1);

        const orderNumberIndex = headers.indexOf('Pedido');
        const trackingCodeIndex = headers.indexOf('Código Rastreio');
        const trackingUrlIndex = headers.indexOf('URL Rastreio');

        const ordersData = dataRows.map((row) => ({
          order_number: row[orderNumberIndex],
          tracking_code: row[trackingCodeIndex],
          tracking_url: row[trackingUrlIndex],
        }));

        const filteredOrdersData = ordersData.filter(order => order.order_number);

        try {
          const response = await updateTrackingExcel(filteredOrdersData, token, userId);
          const { failed_orders } = response;
          setSuccessCount(filteredOrdersData.length - failed_orders.length);
          setErrorCount(failed_orders.length);
          setErrors(failed_orders.map((order: any) => ({
            orderNumber: order.order_number,
            error: order.error,
          })));
        } catch (error) {
          setErrorCount(filteredOrdersData.length);
          setErrors(filteredOrdersData.map(order => ({
            orderNumber: order.order_number,
            error: 'Erro desconhecido'
          })));
        } finally {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Erro ao processar o arquivo:', error);
        setIsLoading(false);
      }
    };

    reader.readAsBinaryString(selectedFile);
  };

  const maxErrorsToShow = 5;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar Códigos de Rastreamento</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <VStack spacing={4} align="center" mt={4}>
              <Spinner size="lg" />
              <Text>Carregando, por favor aguarde...</Text>
              <Text>Pedidos importados com sucesso: {successCount}</Text>
              <Text>Pedidos com erro: {errorCount}</Text>
            </VStack>
          ) : (
            <>
              <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
              <Text color="yellow.600">Insira o arquivo obtido ao clicar em "Gerar Relatório" com as informações de rastreio preenchidas.</Text>
              {successCount > 0 && (
                <Text mt={4} color="green.500">
                  Rastreio de pedidos importados com sucesso: {successCount}
                </Text>
              )}
              {errorCount > 0 && (
                <VStack spacing={2} mt={4} align="start">
                  <Text color="red.600">Erros: {errorCount}</Text>
                  <Text color="red.600">Os seguintes pedidos falharam na atualização do rastreio:</Text>
                  {errors.slice(0, showAllErrors ? errors.length : maxErrorsToShow).map((error) => (
                    <Alert status="error" key={error.orderNumber}>
                      <AlertIcon />
                      <AlertTitle>{`Pedido ${error.orderNumber}`}</AlertTitle>
                      <Text>{`Erro: ${error.error}`}</Text>
                    </Alert>
                  ))}
                  {errors.length > maxErrorsToShow && (
                    <Button onClick={() => setShowAllErrors(!showAllErrors)} variant="link" colorScheme="blue">
                      {showAllErrors ? 'Ver menos' : 'Ver mais'}
                    </Button>
                  )}
                </VStack>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleImportClick} disabled={!selectedFile || isLoading}>
            Importar Excel
          </Button>
          <Button variant="ghost" onClick={onClose} disabled={isLoading}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportExcelModal;
