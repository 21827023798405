/**
 *
 * get value 1234 to 12.34 when decimalPlaces 2
 * get value 1234 to 1234.00 when decimalPlaces 0
 * get value 1234 to 123400.00 when decimalPlaces -2
 */
export function insertDot(value = 0, decimalPlaces = 2) {
  let newValue = +value;
  if (decimalPlaces >= 0) {
    for (let x = 0; x < decimalPlaces; x += 1) {
      newValue /= 10;
    }
    return Number(newValue.toFixed(decimalPlaces));
  }

  for (let x = 0; x < decimalPlaces; x += 1) {
    newValue *= 10;
  }
  return Number(newValue.toFixed(decimalPlaces * -1));
}

export function formatCurrency(num = 0, to = -2, currency = "BRL") {
  const supportedCurrencies = {
    BRL: "pt-BR",
    USD: "en-US",
    EUR: "de-DE",
    GBP: "en-GB",
    JPY: "ja-JP",
    CAD: "en-CA",
    AUD: "en-AU",
  };

  const locale = supportedCurrencies[currency] || "pt-BR";

  const { format: formatPrice } = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  if (!Number.isNaN(+num)) {
    const newNum = insertDot(num, to);
    return formatPrice(newNum); 
  }
  return null;
}