import React from "react";
import LoadingPage from "components/Loading/LoadingPage";
import { Box, Button, FormControl } from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  CreateStoreRequest,
  Store,
  StoreCheckoutType,
  StoreType,
} from "models/Store";
import { useForm } from "react-hook-form";
import { createStore, updateStore } from "controllers/Stores";
import { addTrailingSlash } from "utils/form";
import { notifyError, notifySuccess } from "utils/notification";
import SelectInput from "components/form/SelectInput";
import { capitalizeFirstLetter } from "utils/formatters";
import FormTitle from "components/form/FormTitle";
import FormInput from "components/form/FormInput";

type StoreFormProps = {
  switchShow: Function;
  show: boolean;
  refetch: Function;
  selected?: Store;
};

export default function StoreForm({
  switchShow,
  show,
  refetch,
  selected,
}: StoreFormProps) {
  const [loading, setLoading] = React.useState(false);

  const defaultValues = selected
    ? {
        name: selected.name,
        description: selected.description,
        email: selected.email,
        support_phone: selected.support_phone,
        site: selected.site,
        url: selected.url,
        api_key: selected.api_key,
        api_password: selected.api_password,
        shared_secret: selected.shared_secret,
        type: selected.type,
        checkout_type: selected.checkout_type,
        language: selected.language || "portuguese", 
      }
    : {
        type: StoreType.SHOPIFY,
        checkout_type: StoreCheckoutType.SINGLE_PRODUCT,
        language: "portuguese", 
      };

  const { register, handleSubmit, reset, watch } = useForm<CreateStoreRequest>({
    defaultValues,
  });
  const storeType = watch("type");

  const handleSave = async (data: CreateStoreRequest) => {
    let storeData: CreateStoreRequest = {
      name: data.name,
      description: data.description,
      email: data.email,
      site: data.site,
      support_phone: data.support_phone,
      type: data.type,
      checkout_type: data.checkout_type,
      language: data.language,
    };

    if (data.type === StoreType.SHOPIFY) {
      storeData = {
        ...storeData,
        url: addTrailingSlash(data.url),
        api_key: data.api_key,
        api_password: data.api_password,
        shared_secret: data.shared_secret,
      };
    }

    if (selected) {
      await handleUpdateStore(storeData);
    } else {
      await handleCreateStore(storeData);
    }
  };

  const handleCreateStore = async (data: CreateStoreRequest) => {
    try {
      setLoading(true);

      await createStore(data);

      notifySuccess("Loja criada com sucesso!");

      refetch();
      switchShow();
    } catch (error: any) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStore = async (data: CreateStoreRequest) => {
    try {
      setLoading(true);

      await updateStore(selected.id, data);

      notifySuccess("Loja atualizada com sucesso!");
      refetch();
      switchShow();
    } catch (error: any) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />
      <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <FormTitle title={selected ? "Editar Loja" : "Nova Loja"} />
        <Box>
          <form onSubmit={handleSubmit(handleSave)}>
            <FormControl rowGap="20px">
              {!selected && (
                <SelectInput
                  register={register}
                  id="type"
                  label="Tipo *"
                  options={Object.keys(StoreType).map((key) => ({
                    value: key.toLowerCase(),
                    label: capitalizeFirstLetter(key),
                  }))}
                  placeholder="Selecione um tipo"
                />
              )}

              <FormInput register={register} id="name" label="Nome da Loja *" />
              <FormInput
                register={register}
                id="email"
                type="email"
                label="E-mail da Loja *"
              />
              <FormInput
                register={register}
                id="description"
                label="Descrição *"
              />
              <FormInput
                register={register}
                id="site"
                label="Site Público *"
                placeholder="https://sua-loja.com.br"
              />
              <FormInput
                register={register}
                id="support_phone"
                label="Telefone do Suporte *"
                placeholder="(11) 99999-9999"
                mask="(99) 9999-9999" // Passa a máscara como uma propriedade opcional
                maskChar={null} // Passa o maskChar como uma propriedade opcional
              />
              <SelectInput
                register={register}
                id="language"
                label="Idioma Checkout*"
                options={[
                  { value: "portuguese", label: "Português" },
                  { value: "english", label: "Inglês" },
                  { value: "german", label: "Alemão" },
                ]}
              />
              {storeType === StoreType.SHOPIFY && (
                <>
                  <FormInput
                    register={register}
                    id="url"
                    label="URL Shopify *"
                    placeholder="https://sua-loja.myshopify.com/"
                  />
                  <FormInput
                    register={register}
                    id="api_key"
                    label="Chave da API *"
                    placeholder="shpat_00000000000000000000000000000000"
                  />
                  <FormInput
                    register={register}
                    id="api_password"
                    label="Senha da API *"
                  />
                  <FormInput
                    register={register}
                    id="shared_secret"
                    label="Segredo Compartilhado *"
                  />
                  <SelectInput
                    register={register}
                    id="checkout_type"
                    label="Tipo do Checkout *"
                    options={Object.values(StoreCheckoutType).map((value) => ({
                      value: value,
                      label:
                        value === StoreCheckoutType.SINGLE_PRODUCT
                          ? "Produto Único"
                          : "Carrinho",
                    }))}
                    placeholder="Selecione um tipo"
                  />
                </>
              )}

              <Button type="submit" colorScheme="green">
                Salvar
              </Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
