import axios from "./api";
import { GetOrders, GetOrdersSum, GetViews, GetHighlightProduct, GetOrdersPerDay } from "models/Metrics";
import { useUserStore } from "store/reducers/user";

export const getOrderMetrics = async (params: GetOrders.Params): Promise<GetOrders.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/metrics/dashboard/orders`;
  try {
    const orders = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: {
          daysAgo: params.daysAgo,
          startDate: params.startDate,
          endDate: params.endDate,
          store_id: params.storeId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return orders.data as GetOrders.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getOrderSumMetrics = async (params: GetOrdersSum.Params): Promise<GetOrdersSum.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/metrics/dashboard/orders/sum`;
  try {
    const orders = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: {
          daysAgo: params.daysAgo,
          startDate: params.startDate,
          endDate: params.endDate,
          store_id: params.storeId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return orders.data as GetOrdersSum.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getProductMetrics = async (params: GetHighlightProduct.Params): Promise<GetHighlightProduct.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/metrics/dashboard/products/highlights`;
  try {
    const orders = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: {
          daysAgo: params.daysAgo,
          startDate: params.startDate,
          endDate: params.endDate,
          store_id: params.storeId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return orders.data as GetHighlightProduct.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getViewMetrics = async (params: GetViews.Params): Promise<GetViews.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/metrics/dashboard/orders/views`;
  try {
    const orders = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: {
          daysAgo: params.daysAgo,
          startDate: params.startDate,
          endDate: params.endDate,
          store_id: params.storeId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return orders.data as GetViews.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getOrdersPerDay = async (params: GetOrdersPerDay.Params): Promise<GetOrdersPerDay.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/metrics/dashboard/orders/per-day`;
  try {
    const orders = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: {
          daysAgo: params.daysAgo,
          startDate: params.startDate,
          endDate: params.endDate,
          store_id: params.storeId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return orders.data as GetOrdersPerDay.Result;
  } catch (err) {
    console.log(err);
  }
};
